// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-2019-js": () => import("./../../../src/pages/2019.js" /* webpackChunkName: "component---src-pages-2019-js" */),
  "component---src-pages-2020-js": () => import("./../../../src/pages/2020.js" /* webpackChunkName: "component---src-pages-2020-js" */),
  "component---src-pages-2021-js": () => import("./../../../src/pages/2021.js" /* webpackChunkName: "component---src-pages-2021-js" */),
  "component---src-pages-2022-js": () => import("./../../../src/pages/2022.js" /* webpackChunkName: "component---src-pages-2022-js" */),
  "component---src-pages-2023-js": () => import("./../../../src/pages/2023.js" /* webpackChunkName: "component---src-pages-2023-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-competencies-js": () => import("./../../../src/pages/competencies.js" /* webpackChunkName: "component---src-pages-competencies-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projects-js": () => import("./../../../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-show-js": () => import("./../../../src/pages/show.js" /* webpackChunkName: "component---src-pages-show-js" */),
  "component---src-pages-students-js": () => import("./../../../src/pages/students.js" /* webpackChunkName: "component---src-pages-students-js" */),
  "component---src-pages-test-js": () => import("./../../../src/pages/test.js" /* webpackChunkName: "component---src-pages-test-js" */),
  "component---src-templates-competency-js": () => import("./../../../src/templates/competency.js" /* webpackChunkName: "component---src-templates-competency-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-student-js": () => import("./../../../src/templates/student.js" /* webpackChunkName: "component---src-templates-student-js" */),
  "component---src-templates-student-show-js": () => import("./../../../src/templates/studentShow.js" /* webpackChunkName: "component---src-templates-student-show-js" */)
}

